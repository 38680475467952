<template>
    <v-form
        v-model="isValid"
    >
        <v-row class="mb-8" />
        <v-row
            v-for="(role, index) in getRoles"
            :key="index"
        >
            <!--                          offset-sm="4"-->
            <v-col
                cols="12"
            >
                <v-switch
                    value
                    :input-value="getUserRoles.find(r => r.id == role.id)"
                    class="my-0 py-0 mx-8"
                    :label="role.attributes.display_name"
                    inset
                    disabled
                />
            </v-col>
        </v-row>
    </v-form>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
    name: 'PermissionForm',
    data() {
        return {
            isValid: false,
            errorMessage: ''
        }
    },
    computed: {
        ...mapGetters('permissions', ['getRoles']),
        ...mapGetters(['getUserRoles'])
    },
    created() {
        this.$store.dispatch('permissions/loadRoles');
    },
    methods: {

    }
}
</script>

<style scoped>

</style>
