<template>
    <v-form
        ref="account"
        v-model="isValid"
    >
        <!--      style="visibility: hidden"-->

        <input
            ref="file"
            type="file"
            hidden
            accept="image/*"
            @change="addChangePicture"
        >
        <v-card-text>
            <!--          color="red"-->
            <v-badge
                overlap
                offset-x="15"
                offset-y="20"
                color="primary"
            >
                <v-icon
                    slot="badge"
                    style="z-index: 1;"
                    @click="loadImage"
                >
                    {{ userInfo.picture.url ? 'mdi-close' : 'mdi-pencil' }}
                </v-icon>

                <v-avatar
                    size="100"
                    class="my-3"
                    color="secondary"
                >
                    <img
                        v-if="userInfo.picture.url"
                        width="50"
                        height="50"
                        :src="userInfo.picture.url"
                        alt=""
                    >
                    <v-icon
                        v-else
                        :size="getUserAcronym.length > 1 ? 45 : 55"
                        color="white"
                        v-text="getUserAcronym"
                    />
                </v-avatar>
            </v-badge>

            <v-text-field
                v-model="userInfo.firstname.value"
                class="purple-input"
                :label="$t('users.login.firstname')"
                :error-messages="userInfo.firstname.error"
                :rules="[rules.required]"
                @keyup="userInfo.firstname.error = ''"
            />

            <v-text-field
                v-model="userInfo.lastname.value"
                class="purple-input"
                :label="$t('users.login.lastname')"
                :error-messages="userInfo.lastname.error"
                @keyup="userInfo.lastname.error = ''"
            />

            <v-text-field
                v-model="userInfo.userid.value"
                class="purple-input"
                :label="$t('users.login.username')"
                :error-messages="userInfo.userid.error"
                :rules="[rules.required]"
                @keyup="userInfo.userid.error = ''"
            />

            <v-text-field
                v-model="userInfo.email.value"
                :label="$t('users.login.email')"
                class="purple-input"
                :error-messages="userInfo.email.error"
                :rules="[rules.required, rules.validEmail]"
                @keyup="userInfo.email.error = ''"
            />

            <div class="error-wrapper">
                <span class="error--text">{{ errorMessage }}</span>
            </div>
        </v-card-text>
        <v-card-actions>
            <v-btn
                :disabled="!isValid && !serverCheck"
                class="mb-4"
                block
                color="primary"
                @click="submit"
            >
                {{ $t('users.profile.tab.basicInfo.changeBasicInfo') }}
            </v-btn>
        </v-card-actions>
    </v-form>
</template>

<script>
import rules from '@/helpers/rulesValidate'
import axios from '@/plugins/axios'
import { mapActions, mapGetters } from 'vuex'

export default {
    name: 'BasicInfoForm',
    data() {
        return {
            isValid: false,
            serverCheck: false,
            rules: {
                ...rules
            },
            userInfo: this.loadUserInfo(),
            errorMessage: ''
        }
    },
    computed: {
        ...mapGetters(['getUser', 'getUserAcronym', 'getUserPicture'])
    },
    mounted() {
        this.userInfo = this.loadUserInfo();
    },
    methods: {
        ...mapActions(['setUserSimple']),
        loadUserInfo() {
            return {
                firstname: {
                    value: this.getUser?.name ?? "",
                    error: ""
                },
                lastname: {
                    value: this.getUser?.lastname ?? "",
                    error: ""
                },
                userid: {
                    value: this.getUser?.username ?? "",
                    error: ""
                },
                email: {
                    value: this.getUser?.email ?? "",
                    error: ""
                },
                picture: {
                    value: '',
                    url: this.getUserPicture
                }
            }
        },
        async submit() {
            this.loading = true;

            try {
                var formData = new FormData();
                formData.append("name", this.userInfo.firstname.value);
                formData.append("lastname", this.userInfo.lastname.value);
                formData.append("username", this.userInfo.userid.value);
                formData.append("email", this.userInfo.email.value);

                if (this.userInfo.picture.value) {
                    formData.append("picture", this.userInfo.picture.value);
                } else if (!this.userInfo.picture.url) { // si no tiene url es que se eliminó la foto
                    formData.append("cleanPicture", true);
                }

                const result = await axios.post('/updateUserInfo', formData, {
                    timeout: 300000,
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });

                /* const result = await axios
                    .post("/updateUserInfo", {
                        name: this.userInfo.firstname.value,
                        lastname: this.userInfo.lastname.value,
                        username: this.userInfo.userid.value,
                        email: this.userInfo.email.value
                    }); */

                this.loading = false;

                if (result.status === 200) {
                    const user = result.data.user;

                    this.setUserSimple(user);

                    this.$router.push('/');
                }
            } catch (error) {
                /* console.log('error *******')
          console.log(error.response.data.errors) */

                this.loading = false;
                if (error.response.status === 422) {
                    var errors = error.response.data.errors;
                    // {"firstname":["The firstname must be a string."],"lastname":["The lastname must be a string."],"userid":["The userid must be a string."],"email":["The email must be a string.","The email must be a valid email address."],"pwd":["The pwd confirmation does not match."]}
                    if (errors.name) { this.userInfo.firstname.error = this.getServerValidation(errors.name); } // "The name field is required."
                    if (errors.lastname) { this.userInfo.lastname.error = this.getServerValidation(errors.lastname); }
                    if (errors.username) { this.userInfo.userid.error = this.getServerValidation(errors.username, 'username'); }
                    if (errors.email) { this.userInfo.email.error = this.getServerValidation(errors.email, 'email'); } // "The email has already been taken."
                    if (errors.password) { this.userInfo.password.error = this.getServerValidation(errors.password, 'password'); } //  "The password must be at least 6 characters."
                    if (errors.password) { // password_confirmation
                        this.userInfo.password_confirmation.error = this.getServerValidation(errors.password, 'password_confirmation'); // "The password confirmation does not match."
                    }
                }
            }
        /* setTimeout(() => {
            console.log('done')
        }, 1000) */
        },
        getServerValidation(values, field = "") {
            for (let i = 0; i < values.length; i++) {
                const value = values[i];
                if (value) {
                    if (value.indexOf("required") !== -1) {
                        return this.$t('rules.required');
                    } else if (field === 'username' && value.indexOf("already been taken") !== -1) {
                        return this.$t('rules.usernameTaken');
                    } else if (field === 'email' && value.indexOf("already been taken") !== -1) {
                        return this.$t('rules.emailTaken');
                    } else if (field === 'password' && value.indexOf("password must be at least") !== -1) {
                        return this.$t('rules.password');
                    } else if (field === 'password_confirmation' && value.indexOf("password confirmation does not match") !== -1) {
                        return this.$t('rules.passwordConfirmation');
                    }
                }
            }

            return "";
        },
        addChangePicture(event) {
            if (event.target.files && event.target.files.length) {
                const file = event.target.files[0];

                if (file) {
                    this.userInfo.picture.url = URL.createObjectURL(
                        file
                    );

                    this.userInfo.picture.value = file;
                }
                // console.log(file);
            } else {
                this.userInfo.picture.url = null;
                this.userInfo.picture.value = [];
            }
        },
        async loadImage() {
            if (this.userInfo.picture.url) {
                this.userInfo.picture.url = '';
                this.userInfo.picture.value = '';
            } else {
                const fileInputElement = this.$refs.file;
                fileInputElement.click();
            }
        }
    }
}
</script>

<style scoped>

</style>
