<template>
    <div>
        <v-row>
            <v-col>
                <!--              :error-message="computedError"
              :account-action="editUser"
              :credential-action="changePassword"
              :profile="profile"
              :action-label="$t('$vuetify.account.action')"
              @errorReset="errorMessage = ''"-->
                <account-form>
                    {{ $t('users.profile.title') }}
                    <template v-slot:before-submit>
                        <p class="text-caption text-center">
                            {{ $t('$vuetify.common.backIn') }}

                            <router-link to="/">
                                {{ $t('$vuetify.common.homePage', '/') }}
                            </router-link>
                        </p>
                    </template>
                </account-form>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import AccountForm from '@/components/profile/AccountForm'

export default {
    components: { AccountForm },
    data() {
        return {

        }
    },
    methods: {

    }
}
</script>
