<template>
    <v-form
        ref="permission"
        v-model="isValid"
    >
        <v-row class="mb-4" />

        <v-row
            v-for="(group, index) in permissionsFullGrouped"
            :key="index"
        >
            <v-col
                cols="6"
                sm="6"
                class="text-right"
            >
                <!--                              v-model="editedItem.enable"-->
                <label class="mx-2">
                    {{ getPermissionName(group[0].display_name).toUpperCase() }}
                </label>
            </v-col>

            <!--                          offset-sm="4"-->
            <v-col
                cols="6"
                sm="6"
            >
                <v-row>
                    <v-col
                        v-for="item in group"
                        :key="item.id"
                        class="my-0 py-0"
                        cols="12"
                        sm="12"
                    >
                        <!--                              -->
                        <v-switch
                            value
                            :input-value="isAdmin || getMyPermissions.map[item.name]"
                            class="my-0 py-0 mx-2"
                            :label="item.display_name.split(' ')[0]"
                            inset
                            disabled
                        />
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-form>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'PermissionForm',
    data() {
        return {
            isValid: false,
            errorMessage: ''
        }
    },
    computed: {
        permissionsFullGrouped: {
            get() {
                const perm = this.$store.getters['permissions/getPermissions'];

                const result = [];
                let group = [];
                for (let i = 0; i < perm.length; i++) {
                    if (i > 0 && this.getPermissionName(perm[i - 1].name) !== this.getPermissionName(perm[i].name)) {
                        result.push(group);
                        group = [];
                    }

                    group.push(perm[i]);
                }

                if (group.length) {
                    result.push(group);
                }

                return result;
            },
            set(val) {
                return true
            }
        },
        ...mapGetters('permissions', ['getMyPermissions', 'isAdmin'])
    },
    created() {
        this.$store.dispatch('permissions/loadPermissions');
    },
    methods: {
        getPermissionName(name) {
            return name.substring(
                name.indexOf(' ') + 1);
        }
    }
}
</script>

<style scoped>

</style>
